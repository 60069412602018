import PanelItem from '../panel-item';
import {h} from 'hyperapp';

export class MyPanelItem extends PanelItem {

  // You can set your own state and actions by overriding the init method
  init() {
    super.init({
      // State
    }, {
      // Actions
    })
  }

  // Renders the interface
  render(state, actions) {
    return super.render('mypanel', [
      h('span', {}, 'Hello World!')
    ]);
  }
}
